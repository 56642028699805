export var lookupData = {
  "listingTypes":[
    {
      "key": 1,
      "value": "To Rent"
    },
    {
      "key": 2,
      "value": "For Sale"
    }
  ],
  "radiuses": [
    {
      "key": 0,
      "value": "+0 miles"
    },
    {
      "key": 0.25,
      "value": "+1/4 miles"
    },
    {
      "key": 0.5,
      "value": "+1/2 miles"
    },
    {
      "key": 1,
      "value": "+1 mile"
    },
    {
      "key": 2,
      "value": "+2 miles"
    },
    {
      "key": 3,
      "value": "+3 miles"
    },
    {
      "key": 5,
      "value": "+5 miles"
    },
    {
      "key": 10,
      "value": "+10 miles"
    },
    {
      "key": 20,
      "value": "+20 miles"
    }
  ],
  "propertyTypes": [
    {
      "key": 1,
      "value": "Detached",
      "iconUrl": "/icon/detached-house-v1.svg"
    },
    {
      "key": 2,
      "value": "Semi-detached",
      "iconUrl": "/icon/semi-detached-house-v1.svg"
    },
    {
      "key": 3,
      "value": "Terraced",
      "iconUrl": "/icon/terraced-house-v1.svg"
    },
    {
      "key": 4,
      "value": "Bungalow",
      "iconUrl": "/icon/bungalow-v1.svg"
    },
    {
      "key": 6,
      "value": "Flat",
      "iconUrl": "/icon/flat-v1.svg"
    }
  ],
  "salePrices": [
    {
      "key": 50000,
      "value": "£50K"
    },
    {
      "key": 60000,
      "value": "£60K"
    },
    {
      "key": 70000,
      "value": "£70K"
    },
    {
      "key": 80000,
      "value": "£80K"
    },
    {
      "key": 90000,
      "value": "£90K"
    },
    {
      "key": 100000,
      "value": "£100K"
    },
    {
      "key": 110000,
      "value": "£110K"
    },
    {
      "key": 120000,
      "value": "£120K"
    },
    {
      "key": 130000,
      "value": "£130K"
    },
    {
      "key": 140000,
      "value": "£140K"
    },
    {
      "key": 150000,
      "value": "£150K"
    },
    {
      "key": 160000,
      "value": "£160K"
    },
    {
      "key": 170000,
      "value": "£170K"
    },
    {
      "key": 180000,
      "value": "£180K"
    },
    {
      "key": 190000,
      "value": "£190K"
    },
    {
      "key": 200000,
      "value": "£200K"
    },
    {
      "key": 210000,
      "value": "£210K"
    },
    {
      "key": 220000,
      "value": "£220K"
    },
    {
      "key": 230000,
      "value": "£230K"
    },
    {
      "key": 240000,
      "value": "£240K"
    },
    {
      "key": 250000,
      "value": "£250K"
    },
    {
      "key": 260000,
      "value": "£260K"
    },
    {
      "key": 270000,
      "value": "£270K"
    },
    {
      "key": 280000,
      "value": "£280K"
    },
    {
      "key": 290000,
      "value": "£290K"
    },
    {
      "key": 300000,
      "value": "£300K"
    },
    {
      "key": 325000,
      "value": "£325K"
    },
    {
      "key": 350000,
      "value": "£350K"
    },
    {
      "key": 375000,
      "value": "£375K"
    },
    {
      "key": 400000,
      "value": "£400K"
    },
    {
      "key": 425000,
      "value": "£425K"
    },
    {
      "key": 450000,
      "value": "£450K"
    },
    {
      "key": 475000,
      "value": "£475K"
    },
    {
      "key": 500000,
      "value": "£500K"
    },
    {
      "key": 550000,
      "value": "£550K"
    },
    {
      "key": 600000,
      "value": "£600K"
    },
    {
      "key": 650000,
      "value": "£650K"
    },
    {
      "key": 700000,
      "value": "£700K"
    },
    {
      "key": 800000,
      "value": "£800K"
    },
    {
      "key": 900000,
      "value": "£900K"
    },
    {
      "key": 1000000,
      "value": "£1M"
    },
    {
      "key": 1250000,
      "value": "£1.25M"
    },
    {
      "key": 1500000,
      "value": "£1.5M"
    },
    {
      "key": 1750000,
      "value": "£1.75M"
    },
    {
      "key": 2000000,
      "value": "£2M"
    },
    {
      "key": 2500000,
      "value": "£2.5M"
    },
    {
      "key": 3000000,
      "value": "£3M"
    },
    {
      "key": 4000000,
      "value": "£4M"
    },
    {
      "key": 5000000,
      "value": "£5M"
    },
    {
      "key": 7500000,
      "value": "£7.5M"
    },
    {
      "key": 10000000,
      "value": "£10M"
    },
    {
      "key": 15000000,
      "value": "£15M"
    },
    {
      "key": 20000000,
      "value": "£20M"
    }
  ],
  "rentPrices": [
    {
      "key": 100,
      "value": "£100"
    },
    {
      "key": 150,
      "value": "£150"
    },
    {
      "key": 200,
      "value": "£200"
    },
    {
      "key": 250,
      "value": "£250"
    },
    {
      "key": 300,
      "value": "£300"
    },
    {
      "key": 350,
      "value": "£350"
    },
    {
      "key": 400,
      "value": "£400"
    },
    {
      "key": 450,
      "value": "£450"
    },
    {
      "key": 500,
      "value": "£500"
    },
    {
      "key": 600,
      "value": "£600"
    },
    {
      "key": 700,
      "value": "£700"
    },
    {
      "key": 800,
      "value": "£800"
    },
    {
      "key": 900,
      "value": "£900"
    },
    {
      "key": 1000,
      "value": "£1,000"
    },
    {
      "key": 1100,
      "value": "£1,100"
    },
    {
      "key": 1200,
      "value": "£1,200"
    },
    {
      "key": 1300,
      "value": "£1,300"
    },
    {
      "key": 1400,
      "value": "£1,400"
    },
    {
      "key": 1500,
      "value": "£1,500"
    },
    {
      "key": 1600,
      "value": "£1,600"
    },
    {
      "key": 1700,
      "value": "£1,700"
    },
    {
      "key": 1800,
      "value": "£1,800"
    },
    {
      "key": 1900,
      "value": "£1,900"
    },
    {
      "key": 2000,
      "value": "£2,000"
    },
    {
      "key": 2250,
      "value": "£2,250"
    },
    {
      "key": 2500,
      "value": "£2,500"
    },
    {
      "key": 2750,
      "value": "£2,750"
    },
    {
      "key": 3000,
      "value": "£3,000"
    },
    {
      "key": 3500,
      "value": "£3,500"
    },
    {
      "key": 4000,
      "value": "£4,000"
    },
    {
      "key": 4500,
      "value": "£4,500"
    },
    {
      "key": 5000,
      "value": "£5,000"
    },
    {
      "key": 5500,
      "value": "£5,500"
    },
    {
      "key": 6000,
      "value": "£6,000"
    },
    {
      "key": 6500,
      "value": "£6,500"
    },
    {
      "key": 7000,
      "value": "£7,000"
    },
    {
      "key": 8000,
      "value": "£8,000"
    },
    {
      "key": 9000,
      "value": "£9,000"
    },
    {
      "key": 10000,
      "value": "£10K"
    },
    {
      "key": 12500,
      "value": "£12.5K"
    },
    {
      "key": 15000,
      "value": "£15K"
    },
    {
      "key": 20000,
      "value": "£20K"
    },
    {
      "key": 25000,
      "value": "£25K"
    },
    {
      "key": 30000,
      "value": "£30K"
    },
    {
      "key": 35000,
      "value": "£35K"
    },
    {
      "key": 40000,
      "value": "£40K"
    }
  ],
  "saleNumberOfBedrooms": [
    {
      "key": 0,
      "value": "Studio"
    },
    {
      "key": 1,
      "value": "1 bed"
    },
    {
      "key": 2,
      "value": "2 bed"
    },
    {
      "key": 3,
      "value": "3 bed"
    },
    {
      "key": 4,
      "value": "4 bed"
    },
    {
      "key": 5,
      "value": "5+ bed"
    }
  ],
  "rentNumberOfBedrooms": [
    {
      "key": -1,
      "value": "Room"
    },
    {
      "key": 0,
      "value": "Studio"
    },
    {
      "key": 1,
      "value": "1 bed"
    },
    {
      "key": 2,
      "value": "2 bed"
    },
    {
      "key": 3,
      "value": "3 bed"
    },
    {
      "key": 4,
      "value": "4 bed"
    },
    {
      "key": 5,
      "value": "5+ bed"
    }
  ],
  "listed": [
    {
      "key": 0,
      "value": "Anytime"
    },
    {
      "key": 1,
      "value": "Last 24 hours"
    },
    {
      "key": 2,
      "value": "Last 48 hours"
    },
    {
      "key": 3,
      "value": "Last 3 days"
    },
    {
      "key": 7,
      "value": "Last 7 days"
    },
    {
      "key": 14,
      "value": "Last 14 days"
    },
    {
      "key": 30,
      "value": "Last 30 days"
    }
  ],
  "sortBy": [
    {
      "key": "Relevance",
      "value": "Relevance"
    },
    {
      "key": "PriceLowHigh",
      "value": "Lowest price"
    },
    {
      "key": "PriceHighLow",
      "value": "Highest price"
    },
    {
      "key": "Newest",
      "value": "Newest"
    },
    {
      "key": "Oldest",
      "value": "Oldest"
    }
  ],
  "priceTypes": [
    {
      "id": 1,
      "listingTypeId": 1,
      "description": "pw"
    },
    {
      "id": 2,
      "listingTypeId": 1,
      "description": "pcm"
    },
    {
      "id": 3,
      "listingTypeId": 1,
      "description": "Per Quarter"
    },
    {
      "id": 4,
      "listingTypeId": 1,
      "description": "Per Year"
    },
    {
      "id": 5,
      "listingTypeId": 1,
      "description": "Per Night"
    },
    {
      "id": 6,
      "listingTypeId": 2,
      "description": "Fixed Price"
    },
    {
      "id": 7,
      "listingTypeId": 2,
      "description": "From"
    },
    {
      "id": 8,
      "listingTypeId": 2,
      "description": "Offers in region of"
    },
    {
      "id": 9,
      "listingTypeId": 2,
      "description": "Offers over"
    },
    {
      "id": 10,
      "listingTypeId": 2,
      "description": "Price on application"
    },
    {
      "id": 11,
      "listingTypeId": 2,
      "description": "Price on request"
    },
    {
      "id": 12,
      "listingTypeId": 2,
      "description": "Shared Equity"
    },
    {
      "id": 13,
      "listingTypeId": 2,
      "description": "Shared ownership"
    },
    {
      "id": 14,
      "listingTypeId": 2,
      "description": "Guide price"
    },
    {
      "id": 15,
      "listingTypeId": 2,
      "description": "Part buy part rent"
    },
    {
      "id": 16,
      "listingTypeId": 2,
      "description": "Sale by tender"
    }
  ]
}